import serviceAxios from "../http/http";

/**
 * 获取句子
 */
export const sentenceGetApi = () => {
    return serviceAxios({
        url: '/app/sentence/get',
        method: "GET"
    });
};