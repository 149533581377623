<template>
  <div>
    <el-sub-menu v-for="item in menuItem" :key="item.id" :index="item.id" style="width: 100%">
      <template #title>
        <span>{{ item.chineseName }}</span>
      </template>
      <!-- 二级菜单 -->
      <el-menu-item v-for="item2 in item.childMenu" :key="item2.id" :index="item2.id" @click="clickMenuItem(item2.name)">
        {{ item2.chineseName }}
      </el-menu-item>
    </el-sub-menu>
  </div>
</template>
<script>
export default {
  name: "NavMenus",
  props: ["menuItem"],

  setup(props, context) {
    /**
     * 点击二级菜单
     */
    function clickMenuItem(name) {
      // 调用父组件方法获取书籍信息然后渲染
      context.emit('eventIsNum', name);
    }
    return {
      clickMenuItem
    }
  },
};
</script>

<style>
el-menu-item {
  --active-color: red;
}
</style>