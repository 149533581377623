<template>
  <div>
    <teleport to="body">
      <el-row :span="5">
        <TopMenu></TopMenu>
      </el-row>
    </teleport>

    <div class="left-menu" />
    <el-row v-if="isLeftMenuShow" class="el-row-all-class">
      <el-col :span="3">
        <div class="NavigationDefaultActive">
          <div class="mb-2">图书分类</div>
          <el-menu
            :router="false"
            :unique-opened="true"
            default-active=""
            mode="vertical">
            <menus :menuItem="menuData" @eventIsNum="receiveChildMenuSelect" />
          </el-menu>
        </div>
      </el-col>
      <el-col :span="21">
        <div class="el-card-pagination-class">
          <div v-for="(item, index) in elCard" class="el-card-el-col">
            <el-card
              v-if="item.isExistDownloadUrl"
              :key="index"
              class="el-card-class">
              <template #header>
                <div class="card-header">
                  <span class="book-name-class">{{ item.bookName }}</span>
                  <el-button
                    type="primary"
                    class="online-read"
                    @click="clickOnlineRead(item)">
                    在线阅读
                  </el-button>
                  <el-dropdown class="download-el-dropdown-class">
                    <el-button type="primary">
                      下载
                      <el-icon class="el-icon--right">
                        <arrow-down />
                      </el-icon>
                    </el-button>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          v-if="item.isHaveEpub"
                          @click.native="downloadEpub(item.bookId)"
                          >下载 Epub 版本
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="item.isHavePdf"
                          @click.native="downloadPdf(item.bookId)"
                          >下载 Pdf 版本
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="item.isHaveMobi"
                          @click.native="downloadMobi(item.bookId)"
                          >下载 Mobi 版本
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </template>
              <div>
                <div class="book-info-class">
                  <p align="left">作者：{{ item.bookAuthor }}</p>
                  <p align="left" v-if="item.bookTranslator">
                    译者：{{ item.bookTranslator }}
                  </p>
                  <p align="left" v-if="item.doubanScore">
                    豆瓣评分：{{ item.doubanScore }}
                  </p>
                  <p align="left" v-if="item.doubanDiscussNums">
                    豆瓣评价人数：{{ item.doubanDiscussNums }}
                  </p>
                  <p align="left">出版社：{{ item.publishingHouse }}</p>
                  <p align="left">出版日期：{{ item.publishDate }}</p>
                </div>
                <img
                  :src="item.tengxunyunBookImageUrl"
                  class="el-card-img-class"
                  alt="书籍图片无法显示" />
              </div>
            </el-card>
          </div>

          <div>
            <el-pagination
              class="el-pagination-class"
              background
              v-if="elCard.length"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              :current-page="currentPage"
              :page-size="10"
              layout="prev, pager, next"
              :total="totalElCardNumber">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { ElMessage } from 'element-plus';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import store from '@/store/index';
  import NProgress from 'nprogress'; // 引入nprogress插件
  import 'nprogress/nprogress.css'; // 这个nprogress样式必须引入

  import { bookMenuApi } from '@/api/menu';
  import { getBookByPageApi } from '@/api/book';
  import { sentenceGetApi } from '@/api/sentence';
  import { downloadHistoryInsertApi } from '@/api/downloadHistory';
  import menus from './ChildrenMenu.vue';
  import SearchMenu from './SearchMenu.vue';
  import TopMenu from './TopMenu.vue';
  export default {
    setup() {
      // 左侧菜单数据
      const menuData = ref();
      // 是否显示搜索框
      const isSearchShow = ref(false);
      // 是否显示左侧菜单
      const isLeftMenuShow = ref(true);
      // 书籍信息的当前页码
      const currentPage = ref(1);
      // 当前二级菜单名称
      const currentMenuName = ref();
      // 一个二级菜单的所有书籍数量
      const totalElCardNumber = ref();
      // 一个二级菜单的所有书籍信息
      const allBookInfo = ref();
      // 一个二级菜单的一页的书籍信息
      const singlePageBookInfo = ref();
      // 卡片数据，存有书籍信息
      const elCard = ref({});
      // 路由
      const router = useRouter();
      // 当前登录用户
      const username = ref();
      // 接收到的句子
      const receiverSentence = ref();
      const newBookResult = ref();
      // 数组格式
      const newBookResultArray = ref();
      const size = ref('default');

      const userOptions = ref([
        {
          value: 'Option1',
          label: '退出登录',
        },
      ]);
      NProgress.start();

      window.onload = function () {
        NProgress.done();
      };

      /**
       * DOM 加载之后，初始化左侧菜单栏图书分类数据
       */
      onMounted(async () => {
        username.value = store.state.username;
        const appUserId = store.state.userId;
        const data = {
          appUserId: appUserId,
        };
        let res = await bookMenuApi(data);
        console.log(res);
        if (res.data !== null) {
          menuData.value = res.data.data;
        } else {
          ElMessage.error('获取菜单失败');
        }
      });

      const handleSizeChange = (number) => {};

      /**
       * 切换分页
       *
       * @param {number} number
       */
      const handleCurrentChange = async (number) => {
        currentPage.value = number;
        // 新的方式，分页获取
        await getBookByPage(currentMenuName.value, currentPage.value);
        const res = await sentenceGetApi();
        if (res.data != null) {
          // 切换分页的时候获取一下句子
          if (res.data.data != null) {
            receiverSentence.value = res.data.data.sentence;
            store.commit('getSentence', { sentence: receiverSentence.value });
          } else {
            ElMessage.error('获取句子失败');
          }
        }
      };

      /**
       * 获取句子
       */
      async function getSentence() {
        const res = await sentenceGetApi();
        if (res.data != null) {
          const backData = res.data.data;
          if (backData != null) {
            receiverSentence.value = backData.sentence;
            store.commit('getSentence', { sentence: receiverSentence.value });
          } else {
            ElMessage.error('获取句子失败');
          }
        } else {
          ElMessage.error('获取句子失败');
        }
      }

      /**
       * 根据分页获取数据
       *
       * @param bookType 书籍类型：eg 小说
       * @param pageIndex 页码
       */
      async function getBookByPage(bookType, pageIndex) {
        let params = {
          searchType: bookType,
          pageIndex: pageIndex,
        };
        const res = await getBookByPageApi(params);
        if (res.data != null) {
          elCard.value = JSON.parse(JSON.stringify(res.data)).data;
          totalElCardNumber.value = JSON.parse(
            JSON.stringify(res.data)
          ).totalBook;
        }
      }

      /**
       * 接收子组件选了哪个二级菜单
       */
      async function receiveChildMenuSelect(name) {
        currentMenuName.value = name;
        // 切换分页后，把当前页码设为 1
        currentPage.value = 1;
        await getSentence();
        await getBookByPage(name, currentPage.value);
      }

      /**
       * 导出
       *
       * @param {String} bookUrl 书籍 url
       * @param {String} fileName 书籍名称
       * @param {String} fileType 书籍类型
       */
      function exportFile(bookUrl, fileName, fileType) {
        const url = bookUrl;
        const link = document.createElement('a');
        // 新窗口打开
        link.target = '_blank';
        link.style.display = 'none';
        link.href = url;
        link.download = `${fileName}.${fileType}`;
        document.body.appendChild(link);
        link.click();
      }

      /**
       * 下载 pdf
       *
       * @param {number} bookId 书籍 id
       */
      function downloadPdf(bookId) {
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.pdfLink;
            let fileName = item.bookName + '-' + item.bookAuthor + '.pdf';
            exportFile(src, fileName, 'pdf');
            break;
          }
        }
      }

      /**
       * 下载 mobi
       */
      function downloadMobi(bookId) {
        console.log('bookid = ' + bookId);
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.mobiLink;
            let fileName = item.bookName + '-' + item.bookAuthor;
            exportFile(src, fileName, 'mobi');
            break;
          }
        }
      }

      /**
       * 上传下载记录
       *
       * @param bookName
       */
      async function postDownloadInfo(bookId, bookName) {
        let downloadHistoryInsertData = {
          bookId: 0,
          username: '',
        };
        downloadHistoryInsertData.bookId = bookId;
        downloadHistoryInsertData.username = username.value;
        const res = await downloadHistoryInsertApi(downloadHistoryInsertData);
        if (res.data !== null) {
        } else {
          ElMessage.error('失败');
        }
      }

      /**
       * 下载 Epub
       * @param bookId 书籍 id
       */
      function downloadEpub(bookId) {
        console.log('bookid = ' + bookId);
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.epubLink;
            let fileName = item.bookName + '-' + item.bookAuthor;
            // 写入下载数据到数据库中
            postDownloadInfo(bookId, item.bookName);
            exportFile(src, fileName, 'epub');
            break;
          }
        }
      }

      /**
       * 点击搜索
       */
      function clickSearchMenuItem() {
        router.push({
          path: '/SearchMenu',
        });
      }

      /**
       * 点击主页
       */
      function clickHomeMenuItem() {
        isLeftMenuShow.value = true;
        isSearchShow.value = false;
        router.push({
          path: '/',
        });
      }

      /**
       * 点击在线阅读
       */
      function clickOnlineRead(item) {
        isLeftMenuShow.value = false;
        // 书籍链接
        router.push({
          name: 'onlineRead',
          params: {
            bookId: item.bookId,
            bookPath: item.epubLink,
            bookName: item.bookName,
            bookAuthor: item.bookAuthor,
          },
        });
      }

      return {
        handleSizeChange,
        handleCurrentChange,
        receiveChildMenuSelect,
        clickOnlineRead,
        clickSearchMenuItem,
        clickHomeMenuItem,
        exportFile,
        downloadMobi,
        downloadEpub,
        downloadPdf,
        getSentence,

        getBookByPage,
        menuData,
        elCard,
        postDownloadInfo,
        currentPage, // 当前页
        currentMenuName,
        totalElCardNumber,
        allBookInfo,
        isLeftMenuShow, // 左侧菜单栏是否显示
        isSearchShow, // 搜索框是否显示
        username, // 用户名
        userOptions,
        receiverSentence,
        newBookResult,
        newBookResultArray,
        size,
        singlePageBookInfo, // 一页的书籍信息
      };
    },
    data() {
      return {};
    },
    components: {
      menus,
      SearchMenu,
      TopMenu,
    },
  };
</script>
<style lang="scss" scoped>
  @import 'element-plus/theme-chalk/dark/css-vars.css';
  /* 在暗黑模式下的样式 */
  @media (prefers-color-scheme: dark) {
    .TopMenu {
      z-index: 999 !important; /* 或其他适当的值 */
    }
  }
  .TopMenu {
    z-index: 999 !important; /* 或其他适当的值 */
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .left-menu {
    background: rgba(18, 18, 18, 0);
  }

  .mb-2 {
    font-size: 25px;
    font-weight: bold;
    align-items: center;
    margin: 10px;
  }

  .el-menu {
    height: 100%;
    width: 100%;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 24px;
  }

  .el-card-pagination-class {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
  }

  .el-card-el-col {
    margin-bottom: 3vh;
  }

  .el-select.m-2 {
    margin-top: 10px;
  }

  .el-card-img-class {
    width: 200px;
    height: 350px;
    vertical-align: top;
    display: inline-block;
  }

  .book-info-class {
    height: 100%;
    width: 45%;
    vertical-align: top;
    display: inline-block;
    margin: 5px;
  }

  .card-header {
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .NavigationDefaultActive {
    // position: fixed;
    position: fixed;
    width: 12.5vw;
    //width: 240px;
    height: 100%;
  }

  .book-name-class {
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
  }

  // 在线阅读
  .online-read {
    margin-left: auto;
    margin-right: 1vw;
  }

  .el-card-class {
    // height: 500px;
    width: 460px;
    z-index: inherit;
  }
</style>
