import { createStore } from 'vuex'

const store = createStore({
    state: {
        // 存储 token
        Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
        // 存储用户名，登录后在右上角显示
        username: localStorage.getItem('username') ? localStorage.getItem('username') : '',
        // 存储用户 id
        userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : '',

        avatarUrl: localStorage.getItem('avatarUrl') ? localStorage.getItem('avatarUrl') : '',
        // 存储句子
        sentence: localStorage.getItem('sentence') ? localStorage.getItem('sentence') : '',
        // 存储阅读时候的字体大小
        readFontSize: localStorage.getItem('readFontSize') ? localStorage.getItem('readFontSize') : 22,
        // 是否放大
        canvasDprStatus: localStorage.getItem('canvasDprStatus') ? localStorage.getItem('canvasDprStatus') : false,
        // 存储主题选择
        onlineReadTheme: localStorage.getItem('onlineReadTheme') ? localStorage.getItem('onlineReadTheme') : 'gold',
    },

    mutations: {
        changeLogin(state, user) {
            state.Authorization = user.Authorization;
            state.username = user.username;
            state.userId = user.userId;

            localStorage.setItem('userId', user.userId);
            localStorage.setItem('username', user.username);
            localStorage.setItem('Authorization', user.Authorization);
        },

        setUsername(state, user) {
            state.username = user.username;
            localStorage.setItem('username', user.username);
        },

        setAvatarUrl(state, user) {
            state.avatarUrl = user.avatarUrl;
            localStorage.setItem('avatarUrl', user.avatarUrl);
        },

        setOnlineReadTheme(state, user) {
            state.onlineReadTheme = user.onlineReadTheme;
            localStorage.setItem('onlineReadTheme', user.onlineReadTheme);
        },

        getSentence(state, user) {
            state.sentence = user.sentence;
            localStorage.setItem('sentence', user.sentence);
        },

        // 存储字体大小
        setReadFontSize(state, user) {
            state.readFontSize = user.readFontSize;
            localStorage.setItem('readFontSize', user.readFontSize);
        },

        setCanvasDpr(state, user) {
            state.canvasDprStatus = user.canvasDprStatus;
            localStorage.setItem('canvasDprStatus', user.canvasDprStatus);
        }
    }
})

export default store;