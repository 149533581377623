/**
 * @author yunhu
 */
import serviceAxios from "../http/http";

/**
 * 分页获取书籍
 */
export const getBookByPageApi = (params) => {
    return serviceAxios({
        url: '/app/book/getBookByPage',
        method: "GET",
        params
    });
};

/**
 * 搜索书籍
 */
export const getSearchBookApi = (params) => {
    return serviceAxios({
        url: '/app/book/getSearchBook',
        method: "GET",
        params
    });
};


/**
 * 书籍添加高亮
 * 
 * @param {*} data 
 * @returns 
 */
export const addHighLightApi = (data) => {
    return serviceAxios({
        url: "/app/book/addHighLight",
        method: "post",
        data
    });
};

/**
 * 书籍删除高亮
 * 
 * @param {*} data 删除高亮信息
 * @returns 
 */
export const deleteHighLightApi = (data) => {
    return serviceAxios({
        url: "/app/book/deleteHighLight",
        method: "post",
        data
    });
};

/**
 * 书籍获取所有高亮列表
 * 
 * @param {*} data 用户 id 和书籍 id
 * @returns 
 */
export const getHighLightListApi = (data) => {
    return serviceAxios({
        url: "/app/book/getHighLightList",
        method: "post",
        data
    });
};
