import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index' // 加载 router 底下的 index.js 路由配置文件
import store from './store/index'
import ElementPlus from 'element-plus'
import serviceAxios from './http/http'

import 'element-plus/dist/index.css'
import 'lib-flexible'
import 'element-plus/theme-chalk/dark/css-vars.css'
import NProgress from 'nprogress' // 引入 nprogress 插件
import 'nprogress/nprogress.css'  // 这个 nprogress 样式必须引入
// import './style/dark/css-vars.css'
// import { IconPark } from '@/plugins/myIconPark'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { Play, FontSizeTwo, DoubleLeft, DoubleRight, Switch, HighLight, Share, CopyOne, TextUnderline, Ripple, ShareOne } from '@icon-park/vue-next'

const app = createApp(App)

app.component('Play', Play);
app.component('FontSizeTwo', FontSizeTwo);
app.component('DoubleLeft', DoubleLeft);
app.component('DoubleRight', DoubleRight);
app.component('ThemeSwitch', Switch);
app.component('HighLight', HighLight);
app.component('Share', Share);
app.component('CopyOne', CopyOne)
app.component('TextUnderline', TextUnderline);
app.component('Ripple', Ripple);
app.component('ShareOne', ShareOne)


// IconPark(app)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 全局挂载 axios 
app.config.globalProperties.$axios = serviceAxios
app.use(router)
app.use(store)

app.use(ElementPlus)
app.mount('#app')