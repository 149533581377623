import { createRouter, createWebHistory } from 'vue-router'
import { ElMessage } from "element-plus";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import routes from './routes'    // 导入 router 目录下的 router.js

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/**
 * 全局前置路由守卫
 */
router.beforeEach((to, from, next) => {
    NProgress.start()

    // document.title = to.meta.title ? to.meta.title : defaultTitle
    if (to.path === '/user/login' || to.path === '/user/register') {
        next();
    } else {
        const token = localStorage.getItem('Authorization'); // 获取token
        // console.log("beforeEach token = " + token);
        // token 不存在
        if (token === null || token === '') {
            ElMessage.error('你还没有登录，请先登录');
            // 跳转到登录页面
            next('/user/login');
        } else {
            next();
        }
    }
});

router.afterEach(() => {
    NProgress.done()
})

export default router;