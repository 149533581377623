<template>
  <div class="Top-Menu-class">
    <el-row :span="4">
      <el-col :span="24">
        <el-menu
          :default-active="1"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :ellipsis="false">
          <el-menu-item class="sentences" index="1" @click="clickHomeMenuItem()"
            >主页</el-menu-item
          >
          <el-menu-item
            class="sentences"
            index="2"
            @click="clickSearchMenuItem()"
            >搜索</el-menu-item
          >
          <el-menu-item class="sentences" index="3">
            {{ receiverSentence }}</el-menu-item
          >

          <div class="flex-grow" />
          <div class="right-el-menu-item-class">
            <el-menu-item index="4" class="sentences">
              <div v-bind="$attrs" @click.stop="toggleDark()">
                <el-icon v-if="isDark" color="#409EFC" class="no-inherit">
                  <Open />
                </el-icon>
                <el-icon v-else color="#409EFC" class="no-inherit">
                  <TurnOff />
                </el-icon>
              </div>
            </el-menu-item>

            <el-menu-item index="5" class="sentences">
              <el-icon color="#409EFC" class="no-inherit" @click="clickMessage">
                <Bell />
              </el-icon>
            </el-menu-item>

            <el-sub-menu index="6" class="right-el-menu-item">
              <template #title>
                <div>
                  <el-avatar class="el-avatar-class" :src="avatarUrl">
                  </el-avatar>
                </div>
              </template>
              <el-menu-item
                class="sentences"
                index="6-1"
                @click="showUpdateUserData()">
                {{ username }}</el-menu-item
              >
              <el-menu-item
                class="sentences"
                index="6-2"
                @click="showReadHistory()">
                阅读历史</el-menu-item
              >
              <el-menu-item
                class="sentences"
                index="6-3"
                @click="showDownloadHistory()">
                下载历史</el-menu-item
              >
              <el-menu-item
                index="6-4"
                class="sentences"
                @click="projectRemark()">
                项目建议</el-menu-item
              >
              <el-menu-item
                index="6-5"
                @click="outLogin()"
                class="right-el-menu-item"
                >退出登录</el-menu-item
              >
            </el-sub-menu>
          </div>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import store from '@/store/index';
  import { ElMessage } from 'element-plus';
  import { computed, watch } from 'vue';
  import { useDark, useToggle } from '@vueuse/core';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { sentenceGetApi } from '@/api/sentence';

  export default {
    name: 'TopMenu',
    setup() {
      // 用户名
      const username = ref();
      const avatarUrl = ref();
      const router = useRouter();
      // 从后端接收到的句子
      const receiverSentence = ref();
      const isDark = useDark();
      const toggleDark = useToggle(isDark);

      onMounted(() => {
        username.value = store.state.username;
        avatarUrl.value = store.state.avatarUrl;
      });

      // 计算属性获取 store 中的 sentence 的值
      const getStateSentence = computed(() => {
        // 返回的是ref对象
        return store.state.sentence;
      });

      // 监听，当值发生改变
      watch(
        getStateSentence,
        (newVal, oldVal) => {
          receiverSentence.value = newVal;
        },
        { immediate: true, deep: true }
      );

      const getAvatarUrlState = computed(() => {
        return store.state.avatarUrl;
      });

      const getUsernameState = computed(() => {
        return store.state.username;
      });

      watch(
        getAvatarUrlState,
        (newVal, oldVal) => {
          avatarUrl.value = newVal;
        },
        { immediate: true, deep: true }
      );

      watch(
        getUsernameState,
        (newVal, oldVal) => {
          username.value = newVal;
        },
        { immediate: true, deep: true }
      );

      /**
       * 从后端获取句子
       */
      async function getSentence() {
        const res = await sentenceGetApi();
        if (res.data != null) {
          receiverSentence.value = res.data.data.sentence;
          // 获取后存入 store 中
          store.commit('getSentence', { sentence: receiverSentence.value });
          // console.log("getSentence " + receiverSentence.value);
        } else {
          ElMessage.error('获取失败');
        }
      }

      /**
       * 点击主页
       */
      function clickHomeMenuItem() {
        getSentence();
        router.push({
          path: '/',
        });
      }

      /**
       * 点击搜索
       */
      function clickSearchMenuItem() {
        getSentence();
        router.push({
          path: '/searchMenu',
        });
      }

      /**
       * 点击项目文档
       */
      function clickProjectDoc() {
        window.location.href =
          'https://stevenling.github.io/yunhu-library-doc/';
      }

      /**
       * 退出登录
       */
      function outLogin() {
        store.commit('changeLogin', { Authorization: '' });
        router.push({
          path: '/user/login',
        });
      }

      /**
       * 项目建议或评价
       */
      function projectRemark() {
        router.push({
          path: '/projectRemark',
        });
      }

      /**
       * 显示我的下载历史
       */
      function showDownloadHistory() {
        router.push({
          path: '/downloadHistory',
        });
      }

      /**
       * 显示更新个人数据
       */
      const showUpdateUserData = () => {
        router.push({
          path: '/updateUserData',
        });
      };

      /**
       * 显示消息
       */
      const clickMessage = () => {
        ElMessage.error('云胡正在开发中');
      };

      /**
       * 显示阅读记录
       */
      const showReadHistory = () => {
        router.push({
          path: '/readHistory',
        });
      };

      return {
        clickHomeMenuItem,
        clickSearchMenuItem,
        outLogin,
        clickProjectDoc,
        getSentence,
        projectRemark,
        showDownloadHistory,
        showUpdateUserData,
        receiverSentence,
        username,
        getStateSentence,
        toggleDark,
        isDark,
        clickMessage,
        avatarUrl,
        getAvatarUrlState,
        showReadHistory,
        getUsernameState,
      };
    },
  };
</script>

<style scoped>
  @import 'element-plus/theme-chalk/dark/css-vars.css';

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .Top-Menu-class {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100vw;
    z-index: 999;
  }

  .flex-grow {
    flex-grow: 1;
    font-size: 18px;
    flex-wrap: nowrap;
  }

  .right-el-menu-item {
    font-size: 18px;
    margin-right: 20px;
  }

  .right-el-menu-item-class {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    /*align-items: flex-start;*/
  }

  .el-sub-menu__title el-tooltip__trigger el-tooltip__trigger {
    font-size: 18px;
  }

  .el-menu-demo {
    position: fixed;
    top: 0px;
    width: 100vw;
    font-size: 18px;
  }

  /* 在暗黑模式下的样式 */
  @media (prefers-color-scheme: dark) {
    .Top-Menu-class {
      position: fixed;
      top: 0px;
      width: 100vw;
      z-index: 999 !important;
      font-size: 18px;
      background-color: rgba(
        0,
        0,
        0,
        1
      ); /* 调整背景颜色为半透明黑色，根据需要调整透明度 */
      color: #fff; /* 调整文字颜色为白色 */
    }
  }

  .sentences {
    font-size: 18px;
    margin-right: 20px;
  }

  #title {
    font-size: 18px;
  }

  .el-avatar-class {
    margin: 20px;
  }
</style>
