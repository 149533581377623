<template>
  <div class="register">
    <div class="container">
      <el-row>
        <el-col>
          <img
            src="https://image-1253628880.cos.ap-chengdu.myqcloud.com/login-left.jpg"
            class="image"
            alt="显示图片失败" />
        </el-col>
        <el-col>
          <el-card>
            <div class="login-title">注册</div>
            <el-form ref="formRef" :rules="rules" :model="form">
              <el-form-item
                label="用户名"
                prop="username"
                :label-position="labelPosition"
                label-width="80px">
                <el-input v-model="form.username"></el-input>
              </el-form-item>
              <el-form-item
                label="密码"
                prop="password"
                :label-position="labelPosition"
                label-width="80px">
                <el-input type="password" v-model="form.password"></el-input>
              </el-form-item>

              <el-form-item
                label="邀请码"
                prop="inviteCode"
                :label-position="labelPosition"
                label-width="80px">
                <el-input v-model="form.inviteCode"></el-input>
              </el-form-item>
            </el-form>
            <div class="el-link-class">
              <el-link type="primary" @click="linkToLogin()"
                >已有账号?点击登录</el-link
              >
            </div>
            <el-button type="primary" @click="submit">注册</el-button>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { ElMessage } from 'element-plus';
  import { userRegisterApi } from '@/api/user';

  export default {
    name: 'Register',
    setup() {
      // 获取表单 DOM
      const formRef = ref(null);

      const form = reactive({
        username: '',
        password: '',
        inviteCode: '',
      });

      const router = useRouter();
      const receiverRegisterRes = ref();

      const rules = reactive({
        username: [
          {
            required: true,
            message: '请填写用户名',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: '请填写密码',
            trigger: ['blur', 'change'],
          },
          {
            min: 6,
            message: '密码至少要六位',
          },
        ],
        inviteCode: [
          {
            required: true,
            message: '请填写邀请码',
            trigger: ['blur', 'change'],
          },
          {
            len: 6,
            message: '校验码为六位哦',
          },
        ],
      });

      /**
       * 跳转到登录页面
       */
      function linkToLogin() {
        router.push({
          path: '/user/login',
        });
      }

      /**
       * 点击注册
       */
      async function submit() {
        formRef.value.validate(async (valid) => {
          if (!valid) {
            return;
          }
          let res = await userRegisterApi(form);
          receiverRegisterRes.value = JSON.parse(JSON.stringify(res.data.data));
          if (receiverRegisterRes.value !== null) {
            if (receiverRegisterRes.value.registerResult) {
              ElMessage.success('注册成功');
              await router.push({
                path: '/user/login',
              });
            } else {
              ElMessage.error(res.data.msg);
            }
          }
        });
      }

      return {
        submit,
        linkToLogin,
        form,
        rules,
        formRef,
        receiverRegisterRes,
      };
    },
  };
</script>

<style rel="stylesheet/scss" type="text/scss" lang="scss" scoped>
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* 设置容器高度为视口高度，以充满整个屏幕 */
    /* height: 100vh;  */
    height: 80vh;
  }
  .register {
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .el-card {
    width: 600px;
    height: 480px;
  }

  .image {
    width: 600px;
    height: 480px;
  }

  .el-form-item__label {
    text-align: justify;
    height: 50px;
  }

  .el-button {
    width: 300px;
  }

  .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 80px;
  }

  .el-row {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .el-row:last-child {
    margin-bottom: 0;
  }

  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .el-link-class {
    text-align: right;
    margin: 20px;
  }

  .el-col-24 {
    flex: none;
  }
</style>
