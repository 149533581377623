import serviceAxios from "../http/http";

/**
 * 获取书籍菜单
 */
export const bookMenuApi = (params) => {
    return serviceAxios({
        url: '/app/menu/getAllMenu',
        method: "GET",
        params
    });
};