import serviceAxios from "../http/http";

/**
 * 插入下载历史
 */
export const downloadHistoryInsertApi = (data) => {
    return serviceAxios({
        url: '/app/downloadHistory/insert',
        method: "POST",
        data
    });
};

/**
 * 获取下载历史
 */
export const downloadHistoryGetApi = (data) => {
    return serviceAxios({
        url: '/app/downloadHistory/get',
        method: "POST",
        data
    });
};