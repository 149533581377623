const routes = [
    {
        name: 'BookMenu',
        path: '/',
        component: ()=>import("@/components/BookMenu.vue")
    },
    {
        name: 'SearchMenu',
        path: '/SearchMenu',
        component: ()=>import('@/components/SearchMenu.vue')
    },
    {
        name: 'Login',
        path: '/user/login',
        component: ()=>import('@/components/Login.vue')
    },
    {
        name: 'Register',
        path: '/user/Register',
        component: ()=>import('@/components/Register.vue')
    },
    {
        name: 'ProjectRemark',
        path: "/projectRemark",
        component: ()=>import('@/components/ProjectRemark')
    },
    {
        name: 'CreateProjectRemark',
        path: "/createProjectRemark",
        component: ()=>import('@/components/CreateProjectRemark')
    },
    {
        name: 'DownloadHistory',
        path: "/downloadHistory",
        component: ()=>import('@/components/DownloadHistory')
    },
    {
        name: 'onlineRead',
        path: "/onlineRead",
        component: ()=>import('@/components/OnlineRead')
    },
    {
        name: 'UpdateUserData',
        path: '/updateUserData',
        component: ()=>import("@/views/UpdateUserData")
    },
    {
        name: 'ReadHistory',
        path: '/readHistory',
        component: ()=>import("@/views/ReadHistory")
    }
];

export default routes
