<template>
  <TopMenu></TopMenu>
  <div>
    <!-- <div class="img-div">
      <img
        src="https://image-1253628880.cos.ap-chengdu.myqcloud.com/linkedin_banner_image_2.png"
        class="yunhu-library-logo" />
    </div> -->
    <el-row :span="6">
      <el-col :span="9"> </el-col>
      <el-col :span="6">
        <div class="search-class">
          <el-input
            v-model="userSearchContent"
            class="w-50 m-2"
            placeholder="请输入书名或作者名称">
            <template #prefix>
              <el-icon class="el-input__icon">
                <search />
              </el-icon>
            </template>
          </el-input>

          <el-button
            class="search-button-class"
            type="primary"
            :icon="Search"
            @click.native="clickSearch()"
            >Search</el-button
          >
        </div>
      </el-col>
    </el-row>
    <div class="centered-container">
      <el-card
        v-for="(item, index) in elCard"
        :key="index"
        class="el-card-class">
        <template #header>
          <div class="card-header">
            <span class="book-name-class">{{ item.bookName }}</span>
            <el-button
              type="primary"
              class="online-read"
              @click="clickOnlineRead(item)">
              在线阅读
            </el-button>
            <el-dropdown class="download-el-dropdown-class">
              <el-button type="primary">
                下载
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-if="item.isHavePdf"
                    @click.native="downloadPdf(item.bookId)"
                    >下载 Pdf 版本
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="item.isHaveMobi"
                    @click.native="downloadMobi(item.bookId)"
                    >下载 Mobi 版本
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="item.isHaveEpub"
                    @click.native="downloadEpub(item.bookId)"
                    >下载 Epub 版本
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </template>

        <div class="book-info-class">
          <div class="book-text-class">
            <p align="left">作者：{{ item.bookAuthor }}</p>
            <p align="left" v-if="item.bookTranslator">
              译者：{{ item.bookTranslator }}
            </p>
            <p align="left" v-if="item.bookTrandoubanScoreslator">
              豆瓣评分：{{ item.doubanScore }}
            </p>
            <p align="left" v-if="item.doubanDiscussNums">
              豆瓣评价人数：{{ item.doubanDiscussNums }}
            </p>
            <p align="left">出版社：{{ item.publishingHouse }}</p>
            <p align="left">出版日期：{{ item.publishDate }}</p>
          </div>
          <img
            :src="item.tengxunyunBookImageUrl"
            class="el-card-img-class"
            v-if="item.tengxunyunBookImageUrl" />
        </div>
      </el-card>

      <el-pagination
        background
        class="book-el-pagination-class"
        v-if="elCard.length"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="2"
        layout="prev, pager, next"
        :total="searchBookSum">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import { useRouter } from 'vue-router';
  import { Home } from '@icon-park/vue-next';
  import { ref } from 'vue';
  import { ElMessage } from 'element-plus';
  import TopMenu from './TopMenu.vue';
  import { getSearchBookApi } from '@/api/book';
  import store from '@/store/index';

  export default {
    name: 'SearchMenu',
    setup() {
      const router = useRouter();
      const userSearchContent = ref();
      // 接受到的搜索结果
      const searchBookResult = ref();
      // 将搜索结果过滤
      const searchBookResultSet = ref();
      // 过滤后的结果
      const searchBookResultArray = ref();
      const elCard = ref({});
      // 搜索到的书籍数量
      const searchBookSum = ref();
      // 当前页码
      const currentPage = ref(0);

      /**
       * 点击返回主页
       */
      function clickHomeMenuItem() {
        router.push({
          path: '/',
        });
      }

      /**
       * 点击搜索，往数据库查询
       */
      async function clickSearch() {
        const params = {
          appUserId: store.state.userId,
          searchContent: userSearchContent.value,
        };
        const res = await getSearchBookApi(params);
        if (res.data.data != null) {
          searchBookResult.value = JSON.parse(JSON.stringify(res.data.data));
          // 如何过滤数据相同的 TODO 可以不用 Set
          searchBookResultSet.value = new Set();
          for (let item of searchBookResult.value) {
            // 遍历当前集合
            let flag = false;
            for (let currentSet of searchBookResultSet.value) {
              if (
                item.bookName === currentSet.bookName ||
                item.isExistDownloadUrl === null
              ) {
                // Set 中已经存在不加入
                flag = true;
                break;
              }
            }
            if (!flag) {
              // 不存在加入
              searchBookResultSet.value.add(item);
            }
          }
          console.log(searchBookResultSet.value);
          // 将去重后的 set 集合转为数组
          searchBookResultArray.value = Array.from(searchBookResultSet.value);
          elCard.value = searchBookResultArray.value.slice(0, 2);
          searchBookSum.value = searchBookResultArray.value.length;
          if (searchBookSum.value === 0) {
            ElMessage.error(
              '抱歉, 云胡图书馆暂无「' + userSearchContent.value + '」这本书'
            );
          }
        }
      }

      /**
       * 切换分页
       *
       * @param {number} number
       */
      const handleCurrentChange = (number) => {
        currentPage.value = number;
        elCard.value = [];
        if (number * 2 <= searchBookSum.value) {
          elCard.value = searchBookResultArray.value.slice(
            2 * (number - 1),
            2 * number
          );
          console.log(elCard.value);
        } else {
          elCard.value = searchBookResultArray.value.slice(
            2 * (number - 1),
            searchBookSum.value
          );
        }
      };

      /**
       * 导出
       * @param {} data
       * @param {*} fileName
       * @param {*} fileType
       */
      function exportFile(data, fileName, fileType) {
        const url = data;
        const link = document.createElement('a');
        link.target = '_blank'; //新标签
        link.style.display = 'none';
        link.href = url;
        link.download = `${fileName}.${fileType}`;
        document.body.appendChild(link);
        link.click();
      }

      /**
       * 下载 pdf
       */
      function downloadPdf(bookId) {
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.pdfLink;
            let fileName = item.bookName + '-' + item.bookAuthor;
            exportFile(src, fileName, 'pdf');
            break;
          }
        }
      }

      /**
       * 下载 mobi
       */
      function downloadMobi(bookId) {
        console.log('bookid = ' + bookId);
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.mobiLink;
            let fileName = item.bookName + '-' + item.bookAuthor;
            exportFile(src, fileName, 'mobi');
            break;
          }
        }
      }

      /**
       * 下载 Epub
       */
      function downloadEpub(bookId) {
        console.log('bookid = ' + bookId);
        for (let item of elCard.value) {
          if (item.bookId === bookId) {
            let src = item.epubLink;
            let fileName = item.bookName + '-' + item.bookAuthor;
            exportFile(src, fileName, 'mobi');
            break;
          }
        }
      }

      /**
       * 点击在线阅读
       */
      function clickOnlineRead(item) {
        const src = item.epubLink;
        if (src !== null && src !== '') {
          // 书籍链接
          router.push({
            name: 'onlineRead',
            params: {
              bookId: item.bookId,
              bookPath: item.epubLink,
              bookName: item.bookName,
              bookAuthor: item.bookAuthor,
            },
          });
        }
        if (src === '' || src === null || src === undefined) {
          ElMessage.error('抱歉, 云胡图书馆没有这本书, 无法在线阅读');
        }
      }

      return {
        clickOnlineRead,
        clickHomeMenuItem,
        clickSearch,
        exportFile,
        downloadPdf,
        downloadMobi,
        downloadEpub,
        handleCurrentChange,

        userSearchContent,
        searchBookResult,
        elCard,
        searchBookSum,
        currentPage,
        searchBookResultSet,
        searchBookResultArray,
      };
    },
    components: {
      Home,
      TopMenu,
    },
  };
</script>

<style lang="scss" scoped>
  .w-50 m-2 {
    margin-top: 20px;
  }

  .search-class {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  .centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh;
  }

  .search-button-class {
    margin-left: 20px;
  }

  .yunhu-library-logo {
    width: 460px;
    height: 230px;
    margin-top: 0px;
  }

  .el-menu {
    margin-left: 60px;
    border-right-width: 0;
  }

  .el-card-class {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 40vh;
    width: 18vw;
  }

  .book-el-pagination-class {
    margin-top: 80px;
  }

  .el-card-img-class {
    width: 50%;
    height: 100%;
    vertical-align: top;
    display: inline-block;
  }

  .book-info-class {
    display: flex;
    flex-direction: row;
  }

  .book-text-class {
    height: 100%;
    width: 40%;
    vertical-align: top;
    display: inline-block;
    margin: 5px;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .download-el-dropdown-class {
    align-items: flex-start;
  }

  .el-col el-col-4 {
    margin-top: 10px;
  }

  .NavigationDefaultActive {
    position: fixed;
    top: 100px;
    left: 10px;
    width: 200px;
  }

  .book-name-class {
    font-size: 18px;
    font-weight: bold;
  }

  .online-read {
    margin-left: auto;
    margin-right: 1vw;
  }
</style>
