import serviceAxios from "../http/http";

/**
 * 用户注册
 *
 * @param data
 */
export const userRegisterApi = (data) => {
    return serviceAxios({
        url: "/app/user/register",
        method: "post",
        data
    });
};

/**
 * 用户登录
 *
 * @param data
 */
export const userLoginApi = (data) => {
    return serviceAxios({
        url: "/app/user/login",
        method: "post",
        data
    });
};

/**
 * 用户获取验证码
 */
export const userGetVerificationCodeApi = () => {
    return serviceAxios({
        url: "/app/user/getVerificationCode",
        method: "get",
    });
};

/**
 * 用户保存个人资料
 */
export const userSaveInformationApi = (data) => {
    return serviceAxios({
        url: "/app/user/saveInformation",
        method: "post",
        data
    });
};