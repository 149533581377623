<template>
  <!-- 登录页面 -->
  <div id="box">
    <div class="login">
      <div class="container">
        <el-row :span="24">
          <el-col>
            <img
              src="https://image-1253628880.cos.ap-chengdu.myqcloud.com/login-left.jpg"
              class="image"
              alt="显示图片失败" />
          </el-col>
          <el-col>
            <el-card>
              <div class="login-title">登录</div>
              <el-form ref="formRef" :rules="rules" :model="form">
                <el-form-item
                  label="用户名"
                  prop="username"
                  :label-position="labelPosition"
                  label-width="80px">
                  <el-input v-model="form.username"></el-input>
                </el-form-item>
                <el-form-item
                  label="密码"
                  prop="password"
                  :label-position="labelPosition"
                  label-width="80px">
                  <el-input type="password" v-model="form.password"></el-input>
                </el-form-item>

                <el-form-item
                  label="验证码"
                  prop="verificationCode"
                  :label-position="labelPosition"
                  label-width="80px">
                  <el-input v-model="form.verificationCode"></el-input>

                  <div class="verificationCode-class">
                    <img :src="verificationCodeImageSrc" alt="验证码加载失败" />
                    <el-link class="mx-1" @click="updateVerificationCodeImage"
                      >看不清楚, 换一张</el-link
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div class="el-link-class">
                <el-link type="primary" @click="linkToRegister()"
                  >还没账号?点击注册</el-link
                >
              </div>
              <el-button type="primary" @click="submit">登录</el-button>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive, ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { ElMessage } from 'element-plus';
  import loginLeft from '@/assets/login-left.jpg';

  import store from '@/store/index';
  import { userLoginApi, userGetVerificationCodeApi } from '@/api/user';

  export default {
    name: 'Login',
    setup() {
      const uuid = ref('');
      const verificationCodeBase64 = ref('');
      // 图像类型，例如 "image/png" 或 "image/jpeg"
      const imageType = 'image/png';
      // 获取表单 DOM
      const formRef = ref(null);
      const form = reactive({
        username: '',
        password: '',
        uuid: '',
        verificationCode: '',
      });
      const receiverLoginRes = ref();
      const userToken = ref();
      const router = useRouter();
      // 验证码图片
      const verificationCodeImageSrc = ref('');

      onMounted(async () => {
        updateVerificationCodeImage();
      });

      /**
       * 更换验证码图片
       */
      const updateVerificationCodeImage = async () => {
        const res = await userGetVerificationCodeApi();
        if (res.data) {
          if (res.data.data) {
            verificationCodeBase64.value = res.data.data.verificationCodeBase64;
            uuid.value = res.data.data.uuid;

            // 拼接 Base64 图像数据
            verificationCodeImageSrc.value = `data:${imageType};base64,${verificationCodeBase64.value}`;
          }
        }
      };

      const rules = reactive({
        username: [
          {
            required: true,
            message: '请填写用户名',
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          {
            required: true,
            message: '请填写密码',
            trigger: ['blur', 'change'],
          },
          {
            min: 6,
            message: '密码至少要 6 位',
          },
        ],
        verificationCode: [
          {
            required: true,
            message: '请填写验证码',
            trigger: ['blur', 'change'],
          },
          {
            max: 4,
            message: '验证码是 4 位',
          },
        ],
      });

      /**
       * 跳转到注册页面
       */
      function linkToRegister() {
        router.push({
          path: '/user/register',
        });
      }

      /**
       * 点击登录
       */
      async function submit() {
        formRef.value.validate(async (valid) => {
          if (!valid) {
            return;
          }
          form.uuid = uuid.value;
          let res = await userLoginApi(form);
          console.log(res);
          if (res.data) {
            if (res.data.data) {
              receiverLoginRes.value = JSON.parse(
                JSON.stringify(res.data.data)
              );
              if (receiverLoginRes.value.token !== null) {
                userToken.value = receiverLoginRes.value.token;

                store.commit('changeLogin', {
                  Authorization: userToken.value,
                  username: receiverLoginRes.value.username,
                  userId: receiverLoginRes.value.userTableEntity.id,
                });

                store.commit('setAvatarUrl', {
                  avatarUrl: receiverLoginRes.value.userTableEntity.avatarUrl,
                });

                await router.push({
                  path: '/',
                });
                ElMessage.success(
                  '登录成功, 欢迎 ' + receiverLoginRes.value.username
                );
              } else {
                ElMessage.error(res.data.msg);
              }
            }
          }
        });
      }

      return {
        updateVerificationCodeImage,
        submit,
        linkToRegister,
        form,
        rules,
        formRef,
        receiverLoginRes,
        userToken,
        loginLeft,
        uuid,
        verificationCodeBase64,
        verificationCodeImageSrc,
      };
    },
  };
</script>

// rel="stylesheet/scss" type="text/scss" lang="scss"
<style scoped>
  /* body,
  html {
    margin: 0;
    padding: 0;
  } */
  #app {
    height: 100vh; /* 设置容器高度为视口高度，以充满整个屏幕 */
    margin-top: 0 !important;
  }
  #box {
  }

  /* .login {
    // width: 100vw;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
  } */

  .el-card {
    width: 600px;
    height: 480px;
  }

  .image {
    width: 600px;
    height: 480px;
  }

  .el-form-item__label {
    text-align: justify;
    height: 50px;
  }

  .el-button {
    width: 300px;
  }

  .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 80px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* 设置容器高度为视口高度，以充满整个屏幕 */
    /* height: 100vh;  */
    height: 80vh;
  }

  .el-row {
    /* // margin-bottom: 20px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .el-row:last-child {
    margin-bottom: 0;
  }

  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }

  .el-link-class {
    text-align: right;
    margin: 20px;
  }

  .el-col-24 {
    flex: none;
  }
  .verificationCode-class {
    display: flex; /* 启用Flexbox布局 */
    justify-content: flex-start; /* 水平布局，元素之间均匀分布 */
    align-items: center; /* 元素在交叉轴上居中对齐 */
    margin-top: 8px;
  }
  .mx-1 {
    color: #409eff; /* 使用十六进制颜色值设置字体颜色为绿色 */
    margin-left: 22px;
  }
</style>
